var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        { staticClass: "mb-0 tabs-school", attrs: { "content-class": "mt-0" } },
        [
          _c(
            "b-tab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          staticStyle: { "font-size": "1.2rem" },
                        },
                        [_vm._v("Etapas da oportunidade")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSubmitSteps.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                        },
                        [
                          _c("div", { staticClass: "card-header mb-2" }, [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-10 col-8" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _vm._v(" Etapas da oportunidade "),
                                  _c("small", { staticClass: "d-block mt-1" }, [
                                    _vm._v(
                                      " Cadastre o template do e-mail, encontrado no SendGrid e o código do template da mensagem do WhatsApp."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Sempre que a oportunidade tiver a etapa alterada, uma mensagem será enviada via WhatsApp e E-mail. "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-2 col-4" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "btn btn-primary btn-block",
                                    attrs: {
                                      variant: "primary",
                                      type: "button",
                                    },
                                    on: { click: _vm.repeateAgain },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-plus mr-25",
                                    }),
                                    _c("span", [_vm._v("Novo")]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.formSteps,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(_vm.formSteps, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-group-item handle",
                                    },
                                    [
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-1 d-flex-center",
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v("#" + _vm._s(item.id)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-1 d-flex-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                attrs: { type: "button" },
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    icon: "MoveIcon",
                                                    size: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-3 d-flex-left",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.name,
                                                  expression: "item.name",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid":
                                                  _vm.$v.formSteps.$each[index]
                                                    .name.$error,
                                              },
                                              attrs: {
                                                type: "text",
                                                placeholder: "Nome da etapa",
                                              },
                                              domProps: { value: item.name },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "col-md-3" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.template_email_code,
                                                expression:
                                                  "item.template_email_code",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Código do template - Email SendGrid",
                                            },
                                            domProps: {
                                              value: item.template_email_code,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item,
                                                  "template_email_code",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                label: "title",
                                                "item-text": "title",
                                                "item-value": "id",
                                                options: _vm.optionsTemplates,
                                                placeholder:
                                                  "Template Octadesk",
                                                required: "",
                                              },
                                              model: {
                                                value: item.template_octadesk,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "template_octadesk",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.template_octadesk",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-md-1" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-danger btn-block",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmDelete(
                                                    item.uuid,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 24 24",
                                                    width: "16",
                                                    height: "16",
                                                    fill: "none",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5",
                                                      stroke: "currentColor",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5",
                                                      stroke: "currentColor",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M9.5 16.5L9.5 10.5",
                                                      stroke: "currentColor",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M14.5 16.5L14.5 10.5",
                                                      stroke: "currentColor",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                        },
                        [
                          _c("div", { staticClass: "card-header mb-2" }, [
                            _c("h4", { staticClass: "mb-0" }, [
                              _vm._v(" Etapas de encerramento "),
                              _c("small", { staticClass: "d-block mt-1" }, [
                                _vm._v(
                                  " As etapas listadas abaixo serão exibidas quando o usuário encerrar a oportunidade. "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "ul",
                              { staticClass: "list-group mt-1" },
                              _vm._l(_vm.formStepsLast, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: item.order,
                                    staticClass: "list-group-item",
                                  },
                                  [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-md-1 d-flex-center",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(item.id)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 d-flex-left" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.name,
                                                expression: "item.name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.formStepsLast.$each[
                                                  index
                                                ].name.$error,
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "Nome da etapa",
                                            },
                                            domProps: { value: item.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item,
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.template_email_code,
                                              expression:
                                                "item.template_email_code",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Código do template - Email SendGrid",
                                          },
                                          domProps: {
                                            value: item.template_email_code,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "template_email_code",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-4" },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "title",
                                              "item-text": "title",
                                              "item-value": "id",
                                              options: _vm.optionsTemplates,
                                              placeholder: "Template Octadesk",
                                              required: "",
                                            },
                                            model: {
                                              value: item.template_octadesk,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "template_octadesk",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.template_octadesk",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "form-row mt-1 justify-content-end" },
                        [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-success",
                                attrs: {
                                  type: "submit",
                                  disabled: _vm.submited,
                                },
                              },
                              [
                                _vm.submited
                                  ? _c(
                                      "div",
                                      [
                                        _c("b-spinner", {
                                          attrs: {
                                            small: "",
                                            variant: "light",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("Salvar")]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          staticStyle: { "font-size": "1.2rem" },
                        },
                        [_vm._v(" Times ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _c("div", { staticClass: "card-header mb-0" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-10 col-8" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(" Times "),
                          _c("small", { staticClass: "d-block mt-1" }, [
                            _vm._v(
                              " Lista de times cadastrados no CRM da Seducar. "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2 col-4" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass: "btn btn-primary btn-block",
                            attrs: { variant: "primary", type: "button" },
                            on: { click: _vm.openModalNewTeam },
                          },
                          [
                            _c("i", { staticClass: "bi bi-plus mr-25" }),
                            _c("span", [_vm._v("Novo")]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-body p-0" },
                    [
                      _c("b-table", {
                        staticClass: "position-relative",
                        attrs: {
                          "sticky-header": true,
                          "no-border-collapse": true,
                          items: _vm.teams,
                          responsive: "",
                          fields: _vm.tableColumns,
                          "primary-key": "id",
                          "show-empty": "",
                          "empty-text": "Nenhum registro encontrado",
                          "empty-filtered-text": "Nenhum registro encontrado",
                          filter: _vm.filter,
                          "filter-included-fields": _vm.filterOn,
                          striped: "",
                          hover: "",
                        },
                        on: { filtered: _vm.onFiltered },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(users)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("b-badge", [
                                  _vm._v(_vm._s(item.users.length)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(units)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("b-badge", [
                                  _vm._v(_vm._s(item.units.length)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(modalities)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c("b-badge", [
                                  _vm._v(_vm._s(item.modalities.length)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(created_at)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("datePT")(item.created_at, true)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(actions)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex-between text-center m-auto",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass:
                                        "cursor-pointer cursor text-danger",
                                      attrs: { icon: "TrashIcon", size: "16" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDeleteTeam(
                                            item.uuid
                                          )
                                        },
                                      },
                                    }),
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor",
                                      attrs: { icon: "EditIcon", size: "16" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModalEditTeam(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("CustomPaginateTable", {
                    staticStyle: { background: "#fff" },
                    attrs: {
                      "rows-table": _vm.rowsTable,
                      "current-page": _vm.currentPage,
                      "per-page": _vm.perPage,
                      "total-rows": _vm.totalRows,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-create-team",
          attrs: {
            id: "modal-center",
            centered: "",
            title: "Cadastrar Time",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitTeam.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Nome "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formTeamCreate.name,
                        expression: "formTeamCreate.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.$v.formTeamCreate.name.$error },
                    attrs: { type: "text" },
                    domProps: { value: _vm.formTeamCreate.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formTeamCreate,
                          "name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "b-tabs",
                {
                  staticClass: "mb-0 mt-2 tabs-school",
                  attrs: { "content-class": "mt-0" },
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "1.2rem" },
                                },
                                [_vm._v(" Unidades ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.formTeamCreate.units.$error,
                        },
                        attrs: {
                          multiple: "",
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Nome da unidade",
                          options: _vm.optionsUnits,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selected-option-container",
                            fn: function () {
                              return [_c("div")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.formTeamCreate.units,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTeamCreate, "units", $$v)
                          },
                          expression: "formTeamCreate.units",
                        },
                      }),
                      _vm.formTeamCreate.units.length > 0
                        ? _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(
                              _vm.formTeamCreate.units,
                              function (u, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "chip" },
                                  [
                                    _vm._v(" " + _vm._s(u.title) + " "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "chip-close",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItemOnTab(
                                              _vm.formTeamCreate.units,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" x ")]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "1.2rem" },
                                },
                                [_vm._v(" Modalidades ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.formTeamCreate.modalities.$error,
                        },
                        attrs: {
                          multiple: "",
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Nome da modalidade",
                          options: _vm.optionsModalities,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selected-option-container",
                            fn: function () {
                              return [_c("div")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.formTeamCreate.modalities,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTeamCreate, "modalities", $$v)
                          },
                          expression: "formTeamCreate.modalities",
                        },
                      }),
                      _vm.formTeamCreate.modalities.length > 0
                        ? _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(
                              _vm.formTeamCreate.modalities,
                              function (m, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "chip" },
                                  [
                                    _vm._v(" " + _vm._s(m.title) + " "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "chip-close",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItemOnTab(
                                              _vm.formTeamCreate.modalities,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" x ")]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "1.2rem" },
                                },
                                [_vm._v(" Usuários ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          multiple: "",
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Nome do usuário",
                          options: _vm.optionsUsers,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selected-option-container",
                            fn: function () {
                              return [_c("div")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.formTeamCreate.users,
                          callback: function ($$v) {
                            _vm.$set(_vm.formTeamCreate, "users", $$v)
                          },
                          expression: "formTeamCreate.users",
                        },
                      }),
                      _vm.formTeamCreate.users.length > 0
                        ? _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(
                              _vm.formTeamCreate.users,
                              function (u, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "chip" },
                                  [
                                    _vm._v(" " + _vm._s(u.title) + " "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "chip-close",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItemOnTab(
                                              _vm.formTeamCreate.users,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" x ")]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("hr"),
              _c("div", { staticClass: "form-row mt-1 justify-content-end" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.loading
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { small: "", variant: "light" },
                              }),
                            ],
                            1
                          )
                        : !_vm.formTeamCreate.uuid
                        ? _c("div", [_vm._v("Cadastrar")])
                        : _c("div", [_vm._v("Salvar")]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }