<template>
  <div>
    <b-tabs content-class="mt-0" class="mb-0 tabs-school">
      <b-tab active>
        <template #title>
          <span class="font-weight-bold" style="font-size: 1.2rem;">Etapas da oportunidade</span>
        </template>

        <div class="form-row">
          <div class="col-md-12">
            <form @submit.prevent="onSubmitSteps">
              <div
                class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
              >
                <div class="card-header mb-2">
                  <div class="form-row">
                    <div class="col-md-10 col-8">
                      <h4 class="mb-0">
                        Etapas da oportunidade
                        <small class="d-block mt-1">
                          Cadastre o template do e-mail, encontrado no SendGrid e o código do template da mensagem do WhatsApp.<br>
                          Sempre que a oportunidade tiver a etapa alterada, uma mensagem será enviada via WhatsApp e E-mail.
                        </small>
                      </h4>
                    </div>
                    <div class="col-md-2 col-4">
                      <button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgain"
                        class="btn btn-primary btn-block"
                        type="button"
                      >
                        <i class="bi bi-plus mr-25"></i>
                        <span>Novo</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <draggable
                    tag="ul"
                    :list="formSteps"
                    class="list-group mt-1"
                    handle=".handle"
                  >
                    <li
                      v-for="(item, index) in formSteps"
                      :key="index"
                      class="list-group-item handle"
                    >
                      <div class="form-row">
                        <div class="col-md-1 d-flex-center">
                          <strong>#{{ item.id }}</strong>
                        </div>
                        <div class="col-md-1 d-flex-center">
                          <button type="button" class="btn btn-primary">
                            <feather-icon icon="MoveIcon" class="cursor-pointer" size="15" />
                          </button>
                        </div>

                        <div class="col-md-3 d-flex-left">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Nome da etapa"
                            v-model="item.name"
                            :class="{ 'is-invalid':$v.formSteps.$each[index].name.$error }"
                          />
                        </div>

                        <div class="col-md-3">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Código do template - Email SendGrid"
                            v-model="item.template_email_code"
                          />
                        </div>

                        <div class="col-md-3">
                          <v-select
                            label="title"
                            item-text="title"
                            item-value="id"
                            v-model="item.template_octadesk"
                            :options="optionsTemplates"
                            placeholder="Template Octadesk"
                            required
                          >
                          </v-select>
                        </div>

                        <div class="col-md-1">
                          <button type="button" class="btn btn-danger btn-block" @click="confirmDelete(item.uuid, index)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none">
                              <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                  </draggable>             
                </div>
              </div>

              <div
                class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
              >
                <div class="card-header mb-2">
                  <h4 class="mb-0">
                    Etapas de encerramento
                    <small class="d-block mt-1">
                      As etapas listadas abaixo serão exibidas quando o usuário encerrar a oportunidade.
                    </small>
                  </h4>
                </div>
                <div class="card-body">
                  <ul class="list-group mt-1">
                    <li
                      v-for="(item, index) in formStepsLast"
                      :key="item.order"
                      class="list-group-item"
                    >
                      <div class="form-row">
                        <div class="col-md-1 d-flex-center">
                          <strong>{{ item.id }}</strong>
                        </div>                   
                        <div class="col-md-3 d-flex-left">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Nome da etapa"
                            v-model="item.name"
                            :class="{ 'is-invalid': $v.formStepsLast.$each[index].name.$error }"
                          />
                        </div>

                        <div class="col-md-4">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Código do template - Email SendGrid"
                            v-model="item.template_email_code"
                          />
                        </div>

                        <div class="col-md-4">
                          <v-select
                            label="title"
                            item-text="title"
                            item-value="id"
                            v-model="item.template_octadesk"
                            :options="optionsTemplates"
                            placeholder="Template Octadesk"
                            required
                          >
                          </v-select>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="form-row mt-1 justify-content-end">
                <div class="col-md-3">
                  <button type="submit" class="btn btn-block btn-success" :disabled="submited">
                    <div v-if="submited">
                      <b-spinner small variant="light" />
                    </div>
                    <span v-else>Salvar</span>
                  </button>
                </div>
              </div>  
            </form>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold" style="font-size: 1.2rem;">
            Times
          </span>
        </template>

        <div
          class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
        >
          <div class="card-header mb-0">
            <div class="form-row">
              <div class="col-md-10 col-8">
                <h4 class="mb-0">
                  Times
                  <small class="d-block mt-1">
                    Lista de times cadastrados no CRM da Seducar.
                  </small>
                </h4>
              </div>
              <div class="col-md-2 col-4">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="openModalNewTeam"
                  class="btn btn-primary btn-block"
                  type="button"
                >
                  <i class="bi bi-plus mr-25"></i>
                  <span>Novo</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <b-table
              :sticky-header="true"
              :no-border-collapse="true"
              class="position-relative"
              :items="teams"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro encontrado"
              empty-filtered-text="Nenhum registro encontrado"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              striped
              hover
            >
              <template #cell(users)="{ item }">
                <b-badge>{{ item.users.length }}</b-badge>
              </template>

              <template #cell(units)="{ item }">
                <b-badge>{{ item.units.length }}</b-badge>
              </template>

              <template #cell(modalities)="{ item }">
                <b-badge>{{ item.modalities.length }}</b-badge>
              </template>

              <template #cell(created_at)="{ item }">
                {{ item.created_at | datePT(true) }}
              </template>

              <template #cell(actions)="{ item }">
                <div class="d-flex-between text-center m-auto">
                  <feather-icon
                    @click="confirmDeleteTeam(item.uuid)"
                    icon="TrashIcon"
                    class="cursor-pointer cursor text-danger"
                    size="16"
                  />

                  <feather-icon
                    @click="openModalEditTeam(item)"
                    icon="EditIcon"
                    class="cursor-pointer cursor"
                    size="16"
                  />                 
                </div>
              </template>            
            </b-table>
          </div>
          <CustomPaginateTable
            style="background: #fff"
            :rows-table="rowsTable"
            :current-page="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
          />
        </div>
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-center"
      centered
      ref="modal-create-team"
      title="Cadastrar Time"
      hide-footer
      no-close-on-backdrop
    >
      <form @submit.prevent="onSubmitTeam">
        <div class="form-row">
          <div class="col-md-12">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i> 
              Nome
            </label>
            <input
            type="text"            
            v-model="formTeamCreate.name"
            :class="{ 'is-invalid': $v.formTeamCreate.name.$error }"           
            class="form-control">
          </div>
        </div>

        <b-tabs content-class="mt-0" class="mb-0 mt-2 tabs-school">
          <b-tab active>
            <template #title>
              <span class="font-weight-bold" style="font-size: 1.2rem;">
                Unidades
              </span>
            </template>
            <v-select
              multiple
              label="title"
              item-text="title"
              item-value="code"
              placeholder="Nome da unidade"
              :options="optionsUnits"
              v-model="formTeamCreate.units"
              :class="{
                'is-invalid': $v.formTeamCreate.units.$error,
              }"
            >
              <template #selected-option-container>
                <div></div>
              </template>
            </v-select>
            <div class="selected-items" v-if="formTeamCreate.units.length > 0">
              <div
                class="chip"
                v-for="(u, index) in formTeamCreate.units"
                :key="index"
              >
                {{ u.title }}
                <button
                  class="chip-close"
                  type="button"
                  @click="removeItemOnTab(formTeamCreate.units, index)"
                >
                  x
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="font-weight-bold" style="font-size: 1.2rem;">
                Modalidades
              </span>
            </template>
            <v-select
              multiple
              label="title"
              item-text="title"
              item-value="code"
              v-model="formTeamCreate.modalities"
              placeholder="Nome da modalidade"
              :options="optionsModalities"
              :class="{
                'is-invalid': $v.formTeamCreate.modalities.$error,
              }"
            >
              <template #selected-option-container>
                <div></div>
              </template>
            </v-select>
            <div class="selected-items" v-if="formTeamCreate.modalities.length > 0">
              <div
                class="chip"
                v-for="(m, index) in formTeamCreate.modalities"
                :key="index"
              >
                {{ m.title }}
                <button
                  class="chip-close"
                  type="button"
                  @click="removeItemOnTab(formTeamCreate.modalities, index)"
                >
                  x
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="font-weight-bold" style="font-size: 1.2rem;">
                Usuários
              </span>
            </template>
            <v-select
              multiple
              label="title"
              item-text="title"
              item-value="code"
              placeholder="Nome do usuário"
              :options="optionsUsers"
              v-model="formTeamCreate.users"
              >
              <template #selected-option-container>
                <div></div>
              </template>
            </v-select>
            <div class="selected-items" v-if="formTeamCreate.users.length > 0">
              <div
                class="chip"
                v-for="(u, index) in formTeamCreate.users"
                :key="index"
              >
                {{ u.title }}
                <button
                  class="chip-close"
                  type="button"
                  @click="removeItemOnTab(formTeamCreate.users, index)"
                >
                  x
                </button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <hr>
        <div class="form-row mt-1 justify-content-end">
          <div class="col-md-4">
            <button type="submit" class="btn btn-block btn-success">
              <div v-if="loading">
                <b-spinner small variant="light" />
              </div>
              <div v-else-if="!formTeamCreate.uuid">Cadastrar</div>
              <div v-else>Salvar</div>
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style>
.selected-items {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.chip {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.chip-close {
  background: none;
  border: none;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.chip-close:hover {
  color: red;
}
</style>

<script>
import {
  BTabs,
  BForm,
  BTab,
  BCard,
  BCol,
  BSpinner,
  BTable,
  BModal,
  BRow,
  BBadge
} from "bootstrap-vue";
import draggable from "vuedraggable";
import { required, minLength } from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BTabs,
    BCol,
    BForm,
    BSpinner,
    BTab,
    BCard,
    BBadge,
    BRow,
    draggable,
    vSelect,
    BSpinner,
    BTable,
    BModal,
    CustomPaginateTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      steps: [],
      loading: false,
      submited: false,
      formSteps: [],
      formStepsLast: [],
      nextTodoId: 1,
      optionsTemplates: [],
      optionsUnits: [],
      optionsUsers: [],
      optionsModalities: [],
      formTeamCreate: {
        uuid: '',
        name: '',
        units: [],
        users: [],
        modalities: [],
      },
      teams: [],
      filter: null,
      filterOn: [],
      isAddNewUserSidebarActive: false,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "name",
          label: "Nome",
          sortable: false,
        },
        {
          key: "units",
          label: "Unidades",
          sortable: false,
          thStyle: "width: 150px",
          class: "text-center"
        },
        {
          key: "modalities",
          label: "Modalidades",
          sortable: false,
          thStyle: "width: 150px",
          class: "text-center"
        },
        {
          key: "users",
          label: "Usuários",
          sortable: false,
          thStyle: "width: 100px",
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Data",
          sortable: false,
          thStyle: "width: 150px",
          class: "text-center"
        },
        {
          key: "actions",
          label: "",
          sortable: false,
          thStyle: "width: 100px",
          class: "text-center"
        },
      ],
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
    }
  },
  validations: {
    formSteps: {
      $each: {
        name: { required },
      }
    },
    formStepsLast: {
      $each: {
        name: { required },
      }
    },
    formTeamCreate: {
      name: {
        required,
        minLength: minLength(4)
      },
      modalities: { required },
      units: { required },
      users: { required },
    }
  },
  methods: {
    removeItemOnTab(element, index) {
      console.log(element, index)
      element.splice(index, 1);
    },
    openModalNewTeam() {
      this.clearFormTeam()
      this.$refs["modal-create-team"].show();
    },
    openModalEditTeam(item) {
      this.clearFormTeam()
      this.formTeamCreate = item
      this.$refs["modal-create-team"].show();
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getTeams();
    },
    clearFormTeam() {
      this.formTeamCreate = {
        name: "",
        users: [],
        units: [],
        modalities: [],
      };
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getTeams();
    },
    repeateAgain() {
      this.formSteps.push({
        id: this.nextTodoId += this.nextTodoId,
        uuid: '',
        name: '',
        template_email_code: '',
        template_octadesk: '',
      })
    },
    removeItem(index) {
      this.formSteps.splice(index, 1)
    },
    confirmDelete(uuid, index) {
      this.$swal({
        title: "Tem certeza disso?",
        text: "Ao excluir, as oportunidades associadas ficarão sem etapa definida. Deseja continuar?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formSteps.splice(index, 1);
          if (uuid) {
            this.$store.dispatch("Opportunity/deleteStep", uuid).then(() => {
              this.getOpportunitySteps();
              this.$swal({
                icon: "success",
                title: "Deletado!",
                text: "A Etapa foi excluída com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        }
      });
    },
    confirmDeleteTeam(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Opportunity/deleteTeam", uuid).then(() => {
            this.getTeams();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Time deletado com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    async getOpportunitySteps() {
      try {
        const steps = await this.$store.dispatch("Opportunity/fetchSteps");

        // Separar os dois últimos objetos
        this.formStepsLast = steps.slice(-2);

        this.formSteps = steps.slice(0, -2);
      } catch (error) {
        console.error("Error fetching opportunity steps:", error);
      }
    },
    async getTeams() {
      try {
        this.$store
        .dispatch("User/fetchTeams", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          this.teams = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.currentPage = res.meta.current_page;
        });

      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    },
    async onSubmitTeam() {
      this.$v.formTeamCreate.$touch();
     
      if (!this.$v.formTeamCreate.$error) {
        this.submited = true;

        if (!this.formTeamCreate.uuid) {
          await this.$store.dispatch("Opportunity/storeTeam", this.formTeamCreate).then((res) => {
            this.$refs["modal-create-team"].hide();
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false
          });
        } else {
          await this.$store.dispatch("Opportunity/updateTeam", {uuid: this.formTeamCreate.uuid, data: this.formTeamCreate }).then((res) => {
            this.$refs["modal-create-team"].hide();
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false
          });
        }

        this.getTeams()
      }
    },
    async onSubmitSteps() {
      this.$v.formSteps.$touch();

      if (!this.$v.formSteps.$error) {
        this.submited = true;

        try {
          // Atualizar o `sort` de cada etapa com base na posição no array
          this.formSteps.forEach((step, index) => {
            step.sort = index + 1;
          });

          // Atualizar os valores de sort
          this.formStepsLast[0].sort = 999;
          this.formStepsLast[1].sort = 1000;

          const steps = [
            ...this.formSteps,
            ...this.formStepsLast
          ]

          await this.$store.dispatch("Opportunity/updateStep", { steps });
          this.notifyDefault("success");
        } catch (error) {
          console.error("Error updating steps:", error);
        } finally {
          this.submited = false;
        }
      }
    },
  },
  created() {
    this.getOpportunitySteps();
    this.getTeams();

    this.$store
      .dispatch("Config/fetchOctadeskTemplates")
      .then((res) => {
        this.optionsTemplates = res;
      });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-team");
  },
  async mounted() {
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    this.optionsUsers = await this.$store.dispatch("User/fetchUsersCRM");
    this.optionsModalities = await this.$store.dispatch("Opportunity/fetchModalities");
  }
};
</script>